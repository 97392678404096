import { colorThemeVars } from '@/style/partials/palette.css';
import * as styles from './Image.css';

export type TallSVGTransform = {
  url?: string;
  shadow?: boolean;
  id?: string;
} & React.PropsWithChildren;

export const TallSVGTransform = ({ url, id, shadow = true }: TallSVGTransform) => {
  if (!url) return null;

  return (
    <figure className={styles.figureTall({ shadow })}>
      {shadow && (
        <svg viewBox="0 0 649 727" className={styles.shadow({ type: 'tall' })}>
          <path
            fill={colorThemeVars.primary.highlights || 'white'}
            d="M386.206 726.723H36.7C30.8277 726.725 25.0406 725.29 19.8252 722.538C14.6099 719.786 10.1186 715.797 6.72892 710.907C3.33926 706.017 1.15022 700.369 0.345825 694.438C-0.458574 688.506 0.145175 682.464 2.10629 676.819L228.166 25.374C230.699 18.0837 235.386 11.7732 241.581 7.31104C247.776 2.84884 255.175 0.454334 262.76 0.457034H612.3C618.172 0.454944 623.96 1.89033 629.175 4.64257C634.39 7.39481 638.881 11.3835 642.271 16.2733C645.661 21.1631 647.85 26.8113 648.654 32.743C649.459 38.6747 648.855 44.7166 646.894 50.3609L420.799 701.806C418.266 709.097 413.58 715.407 407.385 719.869C401.19 724.332 393.79 726.726 386.206 726.723Z"></path>
        </svg>
      )}
      <div className={styles.tallSvgTransform({ shadow })}>
        <svg width="100%" height="100%" fill="none" viewBox="0 0 649 727">
          <defs>
            <pattern id={'tallPattern-' + id} width="1" height="1" x="0" y="0">
              <image xlinkHref={url} height="100%"></image>
            </pattern>
          </defs>
          <path
            className={styles.imagePath({ flip: false })}
            fill={`url(#tallPattern-${id})`}
            d="M386.206 726.723H36.7C30.8277 726.725 25.0406 725.29 19.8252 722.538C14.6099 719.786 10.1186 715.797 6.72892 710.907C3.33926 706.017 1.15022 700.369 0.345825 694.438C-0.458574 688.506 0.145175 682.464 2.10629 676.819L228.166 25.374C230.699 18.0837 235.386 11.7732 241.581 7.31104C247.776 2.84884 255.175 0.454334 262.76 0.457034H612.3C618.172 0.454944 623.96 1.89033 629.175 4.64257C634.39 7.39481 638.881 11.3835 642.271 16.2733C645.661 21.1631 647.85 26.8113 648.654 32.743C649.459 38.6747 648.855 44.7166 646.894 50.3609L420.799 701.806C418.266 709.097 413.58 715.407 407.385 719.869C401.19 724.332 393.79 726.726 386.206 726.723Z"></path>
        </svg>
      </div>
    </figure>
  );
};

export default TallSVGTransform;
